@import '../helpers/shareds';
@import './modal';

.modal-cadastro2 {
  > .v-card {
    padding: 0;

    .modal-cadastro-toolbar {
      background-color: $colorAccent !important;
      color: #FFF;

      height: 50px !important;

      > .v-toolbar__content {
        height: inherit !important
      }
    }
     .modal-cadastro-titulo {
        padding: 0 16px 5px;
        font-size: em(16px);
        color: $colorPrimary;
        text-transform: uppercase;
        font-weight: bold;

        @media (max-width: 360px) {
          font-size: .8em;
        }
      }

      .modal-cadastro-form {
        // padding: 0 36px;
        &-col {
          &-imput {
            padding: 0 12px;

            &.select-count {
              color: #F00;
              .v-select__selections {
                padding-right: 50px;
          
                .caption {
                  position: absolute;
                  right: 30px;
                }
              }
            }
          }
        }
      }

    .modal-cadastro-footer {
        // padding: 20px 36px 30px;
        padding: 20px;
        border-top: 1px solid rgba(204, 204, 204, .42);
        justify-content: space-between;
      }

      .modal-cadastro-close {
        color: #FFF !important;
        opacity: .9;

        &:hover {
          opacity: 1;
        }
      }

      .modal-cadastro-toolbar {
        // padding-top: 10px;
      }
  }
}

.modal-cadastro2 {
  .modal-cadastro-btn {
    .modal-cadastro-cadastrar {
      @media (max-width: 599px) {
        display: none;
      }
    }
  }

  .modal-cadastro-form {
    .v-card__title {
      padding: 0;
      font-size: 1em;
      font-weight: bold;
    }

    .wrapper-image {
      position: relative;
      .faixa {
        position: absolute;
        bottom: 0;
        background-color: rgba(220, 220, 220, .9);
        display: block;
        width: 100%;
        text-align: center;
        padding: 5px;
        color: #000;
      }
    }
    .percentage {
      input {
        text-align: right;
      }
    }
  }
}

.abas {
  margin: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}

.v-tabs-items {
  margin-top: 0px !important;
}

